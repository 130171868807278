import { useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { analytics } from './Analytics';

const PAGES_TO_TRACK = ['booking', 'onboard'];

const Inner = (props: any) => {
  useEffect(() => {
    const [, base, bookingId, pageId, pageSection] = props.location.pathname.split('/');

    if (!PAGES_TO_TRACK.some(a => a === base.trim())) {
      console.log('not tracking', base);
      return;
    }

    const currentPage = base === 'onboard' ? 'onboard' : pageSection ?? 'landing';

    const trackingKey = base === 'onboard' ? 'tracked_onboard' : `tracked_${bookingId}_${currentPage}`;

    const hasTracked = sessionStorage.getItem(trackingKey);

    if (!hasTracked) {
      // Track the event
      analytics.track(
        currentPage,
        bookingId
          ? {
              bookingId,
            }
          : {},
      );

      // Mark as tracked in session storage
      sessionStorage.setItem(trackingKey, 'true');
    }
  }, [props?.location?.pathname]);

  return null;
};

export const FlowTracking = withRouter(Inner);
