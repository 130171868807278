// src/routes.js
import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { Prompt } from 'react-router-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { FlowTracking } from './FlowTracking';

import { utils } from './lib/utils';
import { ga, reddit } from './lib/analytics';
import { localStorage } from './lib/storage';
import Booking from './components/booking/view';
import BookingsList from './components/bookingsList/bookingsList';
import OpportunityList from './components/bookingsList/opportunityListWrapper';
import UserGetDetails from './components/userGetDetails/view';
import login from './components/login/view';
import registerEmailLead from './components/login/registerEmailLead.view';
import logout from './components/login/logoutView';
import clientAppRedirect from './components/login/clientAppRedirect';
import TestCredentials from './components/login/testCredentials';
// import createAccount from './components/createAccount/view';
import NotFound from './components/simplePages/404View';
import ErrorPage from './components/simplePages/errorPage';
import DealPage from './components/simplePages/dealPage';
import LatestAgreementPage from './components/simplePages/latestAgreementPage';
import CheckUserRegistered from './components/checkUserRegistered';
import NotificationSettings from './components/user/notificationSettingsView';
import MessagesInbox from './components/messages/messagesInboxView';
import MessagesBooking from './components/messages/messagesBookingView';
import MessagesAdmin from './components/messages/messagesAdminView';
import BookingLandingPage from './components/bookingLandingPage/view';
import { AskableSessionsView } from './components/askableSessions/view';

import Live from './components/live/askable-live-root.view';

import App from './components/app/view';
import SaveUserDetails from './components/common/SaveUserDetails/view';
import UserAgentTest from './components/app/checkUserAgent';

import { loginWrapper } from './AuthWrapper';
import { useIsKinde } from './useIsKinde';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import paypalVerification from './components/userGetDetails/pages/paypalVerification';
import { HELP_URL } from './lib/data/constants';
import MobileAppDownload from './components/mobile/index';

const { hasBigSeven, privateRouteInterrupt } = utils;

ga.init();
reddit.init();

const routeInit = props => {
  ga.pageview(`${props.location.pathname}${props.location.search}`);
  const params = utils.urlParams(props.location.search);
  // SAVE POST-LOGIN DESTINATION TO LOCAL STORAGE IF SET IN URL
  if (params.loginDestination) {
    localStorage.save('tagetPath', params.loginDestination);
  }
  if (params._user_id && params._token) {
    if (props.isAuthenticated && params._user_id === localStorage.get('connectedParticipant')) {
      let newUrl = `${props.location.pathname}${props.location.search}`;
      newUrl = newUrl.replace(/\b(_user_id|_token)=.+?(&|$)/g, '').replace(/(\?|&)+$/, '');
      if (props.location.hash) {
        newUrl += props.location.hash;
      }
      window.location.replace(newUrl);
      return;
    }
    localStorage.save('connectedParticipant', params._user_id);
    localStorage.save('participant_access_token', params._token);
    localStorage.save('loginFollowup', 'testCredentials');
  } else if (params._user_id && !props.isAuthenticated) {
    // return null;
    localStorage.save('queryUserId', params._user_id);
  }

  ['utm_source', 'utm_campaign', 'referred_by'].forEach(param => {
    if (params[param]) {
      localStorage.save(param, params[param]);
    }
  });

  utils.fbPixelTrack('PageView');
  reddit.track('PageVisit');
};

const BlockingComponent = props => {
  const leaveWarning = utils.leaveWarningGet();
  return (
    <div>
      <Prompt when={!!leaveWarning} message={leaveWarning || ''} />
      {props.children}
    </div>
  );
};

const RouteWrapper = ({ component: Component, ...wrapperProps }) => {
  const kinde = useKindeAuth();
  const isKinde = useIsKinde();
  const isAuthenticated = isKinde ? kinde.isAuthenticated : !!localStorage.get('participant_access_token');
  routeInit({ ...wrapperProps, isAuthenticated });

  return (
    <Route
      {...wrapperProps}
      render={
        wrapperProps.private
          ? props => {
              if (isAuthenticated) {
                if (utils.isDemoUser()) {
                  if (!wrapperProps.demo) {
                    return <Redirect to="/error/demo-account-access" />;
                  }
                  return <Component {...props} />;
                }
                if (hasBigSeven() || props.match.path.match(/^\/{0,1}onboard/)) {
                  return (
                    <BlockingComponent>
                      {localStorage.get('loginFollowup') === 'testCredentials' ? <TestCredentials /> : <Component {...props} />}
                    </BlockingComponent>
                  );
                }
                privateRouteInterrupt(props.location.pathname);
                return (
                  <Redirect
                    to={{
                      pathname: '/onboard',
                      search: props.location.search,
                      state: { from: props.location },
                    }}
                  />
                );
              }
              privateRouteInterrupt(props.location.pathname);
              // const userIdSearch = props.location.search.match(/(?:^|\?|&)_user_id=([a-fA-F0-9]{24}?)(?:&|$)/);
              if (localStorage.get('queryUserId')) {
                if (localStorage.get('queryUserId').match(/^[0-9a-fA-F]{24}$/)) {
                  return <CheckUserRegistered location={props.location} />;
                }
                localStorage.delete('queryUserId');
              }
              switch (localStorage.get('utm_source')) {
                default:
                  return (
                    <Redirect
                      to={{
                        pathname: '/login',
                        search: props.location.search,
                        state: { from: props.location },
                      }}
                    />
                  );
              }
            }
          : props => (
              <BlockingComponent>
                <Component {...props} />
              </BlockingComponent>
            )
      }
    />
  );
};

const Routes = props => {
  return (
    <ErrorBoundary>
      <Router {...props}>
        <App {...props}>
          <Switch>
            <RouteWrapper path="/booking/:id" exact component={Booking} />

            <RouteWrapper private path="/booking/:id/apply/error/:message" exact component={Booking} />
            <RouteWrapper private path="/booking/:id/apply/message/:message" exact component={Booking} />
            <RouteWrapper private path="/booking/:id/apply/:page/:progress" component={Booking} />
            <RouteWrapper private path="/booking/:id/apply/:page" component={Booking} />
            <RouteWrapper private path="/booking/:id/apply" component={Booking} />

            <RouteWrapper private path="/onboard" exact component={UserGetDetails} />
            <RouteWrapper private path="/onboard/:page" component={UserGetDetails} />

            <RouteWrapper path="/booking/preview/:id" exact component={Booking} />
            <RouteWrapper path="/booking/preview/:id/apply/:page/:progress" component={Booking} />
            <RouteWrapper path="/booking/preview/:id/apply/:page" component={Booking} />
            <RouteWrapper path="/booking/preview/:id/apply" component={Booking} />

            <RouteWrapper private path="/bookings/upcoming" exact component={BookingsList} />
            <RouteWrapper private path="/bookings/past" exact component={BookingsList} />
            <RouteWrapper private path="/bookings/pending" exact component={BookingsList} />
            <RouteWrapper private path="/opportunities" component={OpportunityList} />
            <RouteWrapper path="/paypal-verification" component={paypalVerification} />

            <RouteWrapper path="/latestAgreement" component={LatestAgreementPage} />

            <RouteWrapper path="/notifications" component={NotificationSettings} />

            <RouteWrapper private path="/messages/admin" exact component={MessagesAdmin} />
            <RouteWrapper private path="/messages/booking/:id" component={MessagesBooking} />
            <RouteWrapper private path="/messages" component={MessagesInbox} />

            <RouteWrapper path="/login" component={loginWrapper(login)} />
            <RouteWrapper path="/signup/email" component={loginWrapper(login)} />
            <RouteWrapper path="/signup" component={loginWrapper(login)} />
            <RouteWrapper path="/logout" component={logout} />
            <RouteWrapper private path="/deals/:deal" component={DealPage} />
            <RouteWrapper private path="/deals" component={DealPage} />
            <RouteWrapper path="/register-contact" component={registerEmailLead} />
            <RouteWrapper path="/client-redirect" component={clientAppRedirect} />

            <RouteWrapper path="/welcome/booking/:id/:page" component={BookingLandingPage} />
            <RouteWrapper private path="/askable-sessions/:sessionCode" component={AskableSessionsView} />

            <RouteWrapper private path="/live/setup-check/:page+" component={Live} />
            <RouteWrapper private path="/live/setup-check" component={Live} />
            <RouteWrapper private path="/live/message/:message" component={Live} />
            <RouteWrapper private path="/live/error/:message" component={Live} />

            <RouteWrapper private path="/" exact component={BookingsList} />

            <RouteWrapper path="/help" component={() => <div ref={() => { window.location.href = HELP_URL; }} />} />

            <RouteWrapper path="/mobile" component={MobileAppDownload} />

            <RouteWrapper path="/error/:error" component={ErrorPage} />
            {/* <RouteWrapper path="/__icons" component={IconsList} /> */}
            <RouteWrapper path="/" component={NotFound} />
          </Switch>
          <SaveUserDetails />
          <UserAgentTest />
          <FlowTracking />
        </App>
      </Router>
    </ErrorBoundary>
  );
};

export default Routes;
