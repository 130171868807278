import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';

import moment from 'lib/moment';
import _ from 'lodash';

import { localStorage } from 'lib/storage';
import { utils } from 'lib/utils';
import globals from 'settings/globals';

import { fetchBookingByIdOverview } from 'data/queries/booking/fetchBookingById';
import fetchUserById from 'data/queries/user/fetchUserById';
import findBookingSubmission from 'data/queries/booking/findBookingSubmission';
import participantSessionsByBooking from 'data/queries/booking/participantSessionsByBooking';
import participantConfirmCompletion from 'data/mutations/participantConfirmCompletion';

import { Wrapper, LoadingOverlay } from 'components/common';

import BookingOverviewView from './bookingOverviewView';
import BookingInReviewView from './bookingInReviewView';
import BookingInvitedView from './bookingInvitedView';
import BookingRegisteredView from './bookingRegisteredView';
import BookingFinishedView from './bookingFinishedView';
import BookingWaitlistView from './bookingWaitlistView';
import BookingOnlineTaskView from './bookingOnlineTaskView';
import BookingDiaryStudyView from './BookingDiaryStudyView';
import BookingFooterOverview from './bookingFooterOverview';
import BookingApply from './apply/view';
import { withKinde } from '../../AuthWrapper';

import NoshowReasonDialog from './noshowReasonDialogView';

import NotFound from '../simplePages/404View';

// Styles
import './styles/booking.scss';

function userConfirm(search) {
  if (typeof search !== 'string') {
    return null;
  }
  const uid = (utils.urlParam(search, '_user_confirm') || '').match(/[0-9a-f]{24}/);
  if (uid) {
    return uid[0];
  }
  return null;
}

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // screenerOpen: this.screenerOpen(props)
      // userConfirm: userConfirm(props.location.search),
      // bookedSession: null,
      bookingParticipantDocs: null,
      // waitlist: [],
    };
    // this.view = 'overview';
    // this.view = 'attending';
    // this.view = 'client-preview';
    // this.view = 'ended';

    this.reloadBooking = this.reloadBooking.bind(this);
    this.isPreview = this.isPreview.bind(this);
    this.screenerOpen = this.screenerOpen.bind(this);
    this.bookingBaseUrl = this.bookingBaseUrl.bind(this);
    this.bookingPage = this.bookingPage.bind(this);
    this.screenerPage = this.screenerPage.bind(this);
    this.renderNoshowReason = this.renderNoshowReason.bind(this);

    this.saveStateFromProps = this.saveStateFromProps.bind(this);

    if (localStorage.get('loadingTimeout')) {
      clearTimeout(localStorage.get('loadingTimeout'));
      localStorage.delete('loadingTimeout');
    }
  }

  componentWillMount() {
    this.saveStateFromProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.saveStateFromProps(newProps);
  }

  saveStateFromProps(newProps) {
    if (
      newProps.participantSessionsByBooking &&
      (JSON.stringify(newProps.participantSessionsByBooking.participantSessionsByBooking) !==
        JSON.stringify(this.props.participantSessionsByBooking.participantSessionsByBooking) ||
        this.state.bookingParticipantDocs === null)
    ) {
      this.setState({
        bookingParticipantDocs: _.sortBy(newProps.participantSessionsByBooking.participantSessionsByBooking, doc => _.get(doc, 'session.start', Infinity)),
      });
    }
  }

  reloadBooking() {
    if (_.get(this.props, 'fetchUserById.refetch')) {
      this.props.fetchUserById.refetch();
    }
    this.props.participantSessionsByBooking.refetch();
    this.props.history.push(this.bookingBaseUrl());
  }

  isPreview() {
    return !!this.props.match.path.match(/^\/booking\/preview\//);
  }

  screenerOpen(props) {
    return !!props.match.path.match(/^\/booking(\/preview){0,1}\/:id\/apply/);
  }

  bookingBaseUrl() {
    return `/booking${this.isPreview() ? '/preview' : ''}/${this.props.match.params.id}`;
  }

  screenerPage() {
    return (
      <BookingApply
        fetchBookingById={this.props.fetchBookingByIdOverview}
        queryVariables={{
          findBookingSubmission: (!this.isPreview() && this.props.findBookingSubmission && this.props.findBookingSubmission.variables) || {},
          fetchUserById: (this.props.fetchUserById && this.props.fetchUserById.variables) || {},
          participantSessionsByBooking:
            (!this.isPreview() && _.get(this.props, 'participantSessionsByBooking') && _.get(this.props, 'participantSessionsByBooking.variables')) || {},
        }}
        refetchUserDetails={() => {
          if (this.isPreview()) {
            return false;
          }
          if (_.get(this.props, 'fetchUserById.refetch')) {
            this.props.fetchUserById.refetch();
          }
        }}
        match={this.props.match}
        history={this.props.history}
        bookingBaseUrl={this.bookingBaseUrl()}
        isPreview={this.isPreview()}
        reloadBooking={this.reloadBooking}
      />
    );
  }

  // trackEvent(_event) {
  //     const mixpanelEvent = {
  //         event: _event.event,
  //         payload: {}
  //     };
  //     if (_event.bookingInfo) mixpanelEvent.payload.booking_info = _event.bookingInfo;
  //     if (_event._id) mixpanelEvent.payload._booking_id = _event._id;
  //     mixpanel.track(mixpanelEvent);
  // }

  result(bookingPage, screener = undefined, confirm = undefined) {
    // TODO: REMOVE
    return { bookingPage, screener, confirm };
  }

  bookingPage() {
    if (this.state.bookingParticipantDocs === null) {
      return <LoadingOverlay />;
    }

    const trackingEvent = { event: 'View booking', _id: this.props.match.params.id };

    if (!this.props.fetchBookingByIdOverview.bookingByID) {
      if (this.props.fetchBookingByIdOverview.loading) {
        return <LoadingOverlay />;
      }
      // this.trackEvent({ ...trackingEvent, event: 'Not found' }, 1);
      return <NotFound />;
    }

    const booking = this.props.fetchBookingByIdOverview.bookingByID;
    const sessions = _.sortBy(booking.session, 'start');
    const sessionDuration = utils.sessionDurationString(this.props.fetchBookingByIdOverview.bookingByID.config.session.duration);

    if (this.isPreview()) {
      if (this.props.location.search === '?registered') {
        if (booking.type === 3 || booking.type === 4) {
          let UnmodPageView = BookingOnlineTaskView;
          if (booking.type === 4) {
            UnmodPageView = BookingDiaryStudyView;
          }
          return {
            page: (
              <UnmodPageView
                booking={_.chain(this.props.fetchBookingByIdOverview.bookingByID)
                  .cloneDeep()
                  .set(
                    'config.incentive',
                    _.get(this.props.fetchBookingByIdOverview.bookingByID, 'config.incentive.value')
                      ? this.props.fetchBookingByIdOverview.bookingByID.config.incentive
                      : { type: 1, value: globals.incentive_value_preview_default_quant, currency_symbol: '$' },
                  )
                  .value()}
                user={{}}
                bookingBaseUrl={this.bookingBaseUrl()}
                bookedSessions={[
                  {
                    _id: '000000000000000000000000',
                    session: {},
                    cancel: 0,
                    status: 5,
                  },
                ]}
                history={this.props.history}
                location={this.props.location}
                isAnonymous
                isPreview
              />
            ),
            header: 'previewHeader',
            trackingEvent: { ...trackingEvent, bookingInfo: 'Online Task - Registered - Preview' },
          };
        }
        return {
          page: (
            <BookingRegisteredView
              booking={this.props.fetchBookingByIdOverview.bookingByID}
              bookedSession={{ session: _.sortBy(this.props.fetchBookingByIdOverview.bookingByID.session, 'start')[0] }}
              sessionDuration={sessionDuration}
              isPreview
              bookingBaseUrl={this.bookingBaseUrl()}
              user={{}}
            />
          ),
          header: 'previewHeader',
          trackingEvent: this.screenerOpen(this.props) ? null : { ...trackingEvent, bookingInfo: 'Confirmed - Preview' },
        };
      }

      let defaultIncentiveValue = globals.incentive_value_preview_default;
      if (this.props.fetchBookingByIdOverview.bookingByID.type === 3) {
        defaultIncentiveValue = globals.incentive_value_preview_default_quant;
      }
      const incentive = `${this.props.fetchBookingByIdOverview.bookingByID.config.incentive.currency_symbol || ''}${
        this.props.fetchBookingByIdOverview.bookingByID.config.incentive.value || defaultIncentiveValue
      }`;
      return {
        page: (
          <BookingOverviewView
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            sessionDuration={sessionDuration}
            isPreview
            bookingBaseUrl={this.bookingBaseUrl()}
          />
        ),
        // modal: this.screenerOpen(this.props) ? this.screenerPage() : null,
        footer: this.screenerOpen(this.props) ? null : (
          <BookingFooterOverview
            incentive={incentive}
            duration={sessionDuration}
            durationString={
              this.props.fetchBookingByIdOverview.bookingByID.type === 4 ? utils.diaryStudyDuration(this.props.fetchBookingByIdOverview.bookingByID) : null
            }
            registerClick={() => false}
            bookingType={this.props.fetchBookingByIdOverview.bookingByID.type}
          />
        ),
        header: 'previewHeader',
        trackingEvent: this.screenerOpen(this.props) ? null : { ...trackingEvent, bookingInfo: 'Preview' },
      };
    }

    let bookedSessions = [];

    if (booking.type === 3 || booking.type === 4) {
      // bookedSessions = _.filter(this.state.bookingParticipantDocs, doc => doc.cancel === 0);
      bookedSessions = this.state.bookingParticipantDocs;
      if (bookedSessions && bookedSessions.length > 0) {
        let UnmodPageView = BookingOnlineTaskView;
        if (booking.type === 4) {
          UnmodPageView = BookingDiaryStudyView;
        }

        let onlineTaskLabel = 'In Progress';
        if (_.get(this.props.findBookingSubmission, '0.external_survey_completed')) {
          onlineTaskLabel = 'Submitted';
        }
        if (_.find(bookedSessions, ['status', 1])) {
          onlineTaskLabel = 'Completed';
        }
        return {
          page: (
            <UnmodPageView
              booking={this.props.fetchBookingByIdOverview.bookingByID}
              user={this.props.fetchUserById && this.props.fetchUserById.userByID}
              bookingBaseUrl={this.bookingBaseUrl()}
              bookedSessions={[bookedSessions[0]]}
              findBookingSubmission={this.props.findBookingSubmission}
              history={this.props.history}
              location={this.props.location}
              isAnonymous={!this.props?.isAuthenticated()}
              participantSessionsByBooking={_.get(this.props, 'participantSessionsByBooking')}
            />
          ),
          trackingEvent: { ...trackingEvent, bookingInfo: `Online Task - ${onlineTaskLabel})` },
        };
      }
    }

    bookedSessions = [_.find(this.state.bookingParticipantDocs, doc => doc.cancel === 0 && doc.status === 1)];
    if (bookedSessions && bookedSessions[0]) {
      const bookedSession = bookedSessions[0] || {};
      if (moment().valueOf() > _.get(bookedSessions[0], 'session.end', 0) || booking.status === 5) {
        return {
          page: (
            <div>
              <BookingFinishedView
                booking={this.props.fetchBookingByIdOverview.bookingByID}
                bookedSession={bookedSession}
                sessionDuration={sessionDuration}
                bookingBaseUrl={this.bookingBaseUrl()}
                user={this.props.fetchUserById && this.props.fetchUserById.userByID}
                participantSessionsByBooking={this.props.participantSessionsByBooking}
                inContextLocation={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.in_context.location')}
              />
            </div>
          ),
          trackingEvent: { ...trackingEvent, bookingInfo: 'Finished' },
        };
      }
      return {
        page: (
          <BookingRegisteredView
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            bookedSession={bookedSessions[0]}
            sessionDuration={sessionDuration}
            bookingBaseUrl={this.bookingBaseUrl()}
            isPreview={!this.props?.isAuthenticated()}
            confirmAttendanceAnonymous={!this.props?.isAuthenticated()}
            user={this.props.fetchUserById && this.props.fetchUserById.userByID}
            inContextLocation={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.in_context.location')}
            bookingAgreement={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.agreement')}
          />
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Confirmed' },
      };
    }
    if (booking.status === 5) {
      return {
        page: (
          <div>
            <h1>This opportunity has closed or been removed</h1>
            <p>
              <Link to="/opportunities" className="link">
                Find more opportunities
              </Link>
            </p>
          </div>
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Closed' },
      };
    }

    bookedSessions = _.filter(this.state.bookingParticipantDocs, doc => doc.cancel === 0 && doc.status === 4);
    if (bookedSessions && bookedSessions.length > 0) {
      return {
        page: (
          <BookingInvitedView
            submissionId={this.props.findBookingSubmission.findBookingSubmission[0]._id}
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            sessionDuration={sessionDuration}
            bookingBaseUrl={this.bookingBaseUrl()}
            invitedSession={bookedSessions[0]}
            isPreview={!this.props?.isAuthenticated()}
            confirmAttendanceAnonymous={!this.props?.isAuthenticated()}
            participantSessionsByBooking={this.props.participantSessionsByBooking}
            user={this.props.fetchUserById && this.props.fetchUserById.userByID}
            inContextLocation={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.in_context.location')}
          />
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Invitation' },
      };
    }

    const futureSesssions = _.filter(sessions, session => session.start > moment().valueOf());

    if (!(futureSesssions && futureSesssions.length > 0)) {
      return {
        page: (
          <div>
            <h1>There are no more available sessions for this opportunity</h1>
            <p>
              <Link to="/opportunities" className="link">
                Find more opportunities
              </Link>
            </p>
          </div>
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Too late' },
      };
    }

    if (this.state.bookingParticipantDocs.length === 0) {
      if (booking.status === 1) {
        const incentive = `${this.props.fetchBookingByIdOverview.bookingByID.config.incentive.currency_symbol || ''}${
          this.props.fetchBookingByIdOverview.bookingByID.config.incentive.value
        }`;
        return {
          page: (
            <BookingOverviewView
              booking={this.props.fetchBookingByIdOverview.bookingByID}
              sessionDuration={sessionDuration}
              // isPreview
              bookingBaseUrl={this.bookingBaseUrl()}
            />
          ),
          // modal: this.screenerOpen(this.props) ? this.screenerPage() : null,
          footer: this.screenerOpen(this.props) ? null : (
            <BookingFooterOverview
              incentive={incentive}
              durationString={
                this.props.fetchBookingByIdOverview.bookingByID.type === 4 ? utils.diaryStudyDuration(this.props.fetchBookingByIdOverview.bookingByID) : null
              }
              duration={sessionDuration}
              bookingType={this.props.fetchBookingByIdOverview.bookingByID.type}
              registerClick={() => {
                // this.trackEvent({ ...trackingEvent, event: 'Booking click register', value: undefined });
                // this.setState({ screenerOpen: true });
                this.props.history.push(`${this.bookingBaseUrl()}/apply/screener`);
              }}
            />
          ),
          trackingEvent: this.screenerOpen(this.props) ? null : trackingEvent,
        };
      }
      return {
        page: (
          <div>
            <h1>Booking not found.</h1>
            <p>
              For more information,{' '}
              <Link to="/help" className="link">
                contact Askable
              </Link>
            </p>
          </div>
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Not found' },
      };
    }

    const futureBookedSessions = _.filter(this.state.bookingParticipantDocs, booking_participant => booking_participant.session.start > moment().valueOf());

    bookedSessions = _.filter(futureBookedSessions, doc => doc.cancel === 0 && doc.status === 2);
    if (bookedSessions && bookedSessions.length > 0) {
      return {
        page: (
          <BookingWaitlistView
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            sessionDuration={sessionDuration}
            bookingBaseUrl={this.bookingBaseUrl()}
            // user={this.props.fetchUserById.userByID}
            isPreview={!this.props?.isAuthenticated()}
            confirmAttendanceAnonymous={!this.props?.isAuthenticated()}
            waitlist={this.props.participantSessionsByBooking.participantSessionsByBooking}
            inContextLocation={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.in_context.location')}
          />
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Waitlist', value: bookedSessions.length },
      };
    }
    // bookedSessions = _.filter(futureBookedSessions, doc => doc.cancel === 0);
    if (futureBookedSessions && futureBookedSessions.length > 0) {
      return {
        page: (
          <BookingInReviewView
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            sessionDuration={sessionDuration}
            bookingBaseUrl={this.bookingBaseUrl()}
            isPreview={!this.props?.isAuthenticated()}
            confirmAttendanceAnonymous={!this.props?.isAuthenticated()}
            bookingParticipants={this.state.bookingParticipantDocs}
            findBookingSubmission={this.props.findBookingSubmission}
            user={_.get(this.props.fetchUserById, 'userByID', null)}
            history={this.props.history}
            location={this.props.location}
            inContextLocation={_.get(this.props.findBookingSubmission, 'findBookingSubmission.0.in_context.location')}
          />
        ),
        trackingEvent: this.screenerOpen(this.props) ? null : { ...trackingEvent, bookingInfo: 'In review' },
      };
    }
    // bookedSessions = this.state.bookingParticipantDocs; // TODO: ???
    bookedSessions = _.filter(futureBookedSessions, doc => doc.cancel === 0);
    if (this.state.bookingParticipantDocs.length > 0 && bookedSessions && bookedSessions.length === 0) {
      return {
        page: (
          <div>
            <h1>Your application for this opportunity has been cancelled.</h1>
            <p>
              For more information,{' '}
              <Link to="/help" className="link">
                contact Askable
              </Link>
            </p>
          </div>
        ),
        trackingEvent: { ...trackingEvent, bookingInfo: 'Cancelled' },
      };
    }
    if (booking.status === 1) {
      const incentive = `${this.props.fetchBookingByIdOverview.bookingByID.config.incentive.currency_symbol || ''}${
        this.props.fetchBookingByIdOverview.bookingByID.config.incentive.value
      }`;
      return {
        page: (
          <BookingOverviewView
            booking={this.props.fetchBookingByIdOverview.bookingByID}
            sessionDuration={sessionDuration}
            bookingBaseUrl={this.bookingBaseUrl()}
          />
        ),
        // modal: this.screenerOpen(this.props) ? this.screenerPage() : null,
        footer: this.screenerOpen(this.props) ? null : (
          <BookingFooterOverview
            incentive={incentive}
            durationString={
              this.props.fetchBookingByIdOverview.bookingByID.type === 4 ? utils.diaryStudyDuration(this.props.fetchBookingByIdOverview.bookingByID) : null
            }
            duration={sessionDuration}
            bookingType={this.props.fetchBookingByIdOverview.bookingByID.type}
            registerClick={() => {
              // this.trackEvent({ ...trackingEvent, event: 'Booking click register', value: undefined });
              // this.setState({ screenerOpen: true });
              this.props.history.push(`${this.bookingBaseUrl()}/apply/screener`);
            }}
          />
        ),
        trackingEvent: this.screenerOpen(this.props) ? null : trackingEvent,
      };
    }

    return {
      page: <h1>Booking not found</h1>,
      trackingEvent: { ...trackingEvent, bookingInfo: 'Not found' },
    };
  }

  renderNoshowReason() {
    const bookingParticipant = _.chain(this.props.participantSessionsByBooking)
      .get('participantSessionsByBooking', [])
      .find(doc => doc.cancel === 4 && !doc.cancel_reason)
      .value();

    if (!bookingParticipant) return null;

    return <NoshowReasonDialog open bookingParticipant={bookingParticipant} booking={this.props.fetchBookingByIdOverview.bookingByID} />;
  }

  render() {
    if (localStorage.get('loadingTimeout')) {
      clearTimeout(localStorage.get('loadingTimeout'));
      localStorage.delete('loadingTimeout');
    }
    // if (
    //     this.props.fetchBookingByIdOverview.loading ||
    //     this.props.fetchUserById.loading ||
    //     this.props.participantSessionsByBooking.loading ||
    //     this.props.findBookingSubmission.loading ||
    //     this.state.loading
    // ) {
    //     // localStorage.save('loadingTimeout', setTimeout(() => { utils.window.location.reload(); }, 3000));
    //     return <LoadingOverlay />;
    // }

    const loading =
      _.get(this.props, 'fetchBookingByIdOverview.loading') ||
      false ||
      _.get(this.props, 'fetchUserById.loading') ||
      false ||
      _.get(this.props, 'participantSessionsByBooking.loading') ||
      false ||
      _.get(this.props, 'findBookingSubmission.loading') ||
      false ||
      this.state.loading;

    const booking = this.bookingPage();
    if (!(booking && booking.page)) {
      return loading ? <LoadingOverlay /> : <NotFound />;
    }

    // if (booking.trackingEvent) {
    //     this.trackEvent(booking.trackingEvent, 1);
    // }
    const classes = `bookingPage${booking.screener ? ' screenerOpen' : ''}`;
    return (
      <Wrapper
        header={booking.header || 'mainHeader'}
        headerProgress={booking.headerProgress}
        headerContents={booking.headerContents}
        footerContents={booking.footer}
        modalContents={this.screenerOpen(this.props) ? this.screenerPage() : null}
        modalOpen={!!this.screenerOpen(this.props)}
        className={classes}
      >
        {loading ? <LoadingOverlay /> : booking.page}
        {this.renderNoshowReason()}
      </Wrapper>
    );
  }
}

const BookingDetailsContainer = graphql(fetchBookingByIdOverview, {
  name: 'fetchBookingByIdOverview',
  options: props => ({
    // variables: { id: props.match.params.id, status: 1 },
    variables: { id: props.match.params.id, user: localStorage.get('connectedParticipant') || '000000000000000000000000' },
  }),
});
const UserDetailsContainer = graphql(fetchUserById, {
  name: 'fetchUserById',
  skip: props => !props.isAuthenticated(),
});
const BookingSubmissionContainer = graphql(findBookingSubmission, {
  name: 'findBookingSubmission',
  skip: props => !props.isAuthenticated(),
  options: props => ({
    variables: { userId: localStorage.get('connectedParticipant') || '000000000000000000000000', bookingId: props.match.params.id },
  }),
});
const ParticipantSessionsByBookingContainer = graphql(participantSessionsByBooking, {
  name: 'participantSessionsByBooking',
  options: props => ({
    variables: {
      _user_id: localStorage.get('connectedParticipant') || userConfirm(utils.window.location.search) || '000000000000000000000000',
      _booking_id: props.match.params.id,
    },
  }),
});

const participantConfirmCompletionContainer = graphql(participantConfirmCompletion, {
  options: props => ({
    refetchQueries: [
      {
        query: participantSessionsByBooking,
        variables: { _user_id: localStorage.get('connectedParticipant') || '000000000000000000000000', _booking_id: props.match.params.id },
      },
    ],
  }),
  props: ({ mutate }) => ({
    participantConfirmCompletion: participant_session_id =>
      mutate({
        variables: { participant_session_id },
        refetchQueries: [
          {
            query: participantSessionsByBooking,
            variables: { _user_id: localStorage.get('connectedParticipant') || '000000000000000000000000' },
          },
        ],
      }),
  }),
});

export default withKinde(
  compose(
    BookingDetailsContainer,
    UserDetailsContainer,
    BookingSubmissionContainer,
    ParticipantSessionsByBookingContainer,
    participantConfirmCompletionContainer,
  )(Booking),
);
