import mixpanel from 'mixpanel-browser';
import { uid } from 'radash';

/**
 * Store a unique flowID into the local storage when the user lands from facebook
 * and use it to track the user's journey through the booking id signup
 */

/**
 * Events to track
 * campaign_landed - when the user lands on the site from an ad campaign
 * authentication_completed - when the user completes the signup or login flow
 * onboarding_started - when the user starts onboarding
 * onboarding_completed - when the user completes onboarding
 * register_started - when the user clicks the register button
 * register_completed - when the user completes the register flow -- SUCCESS EVENT. Anything else is a failure
 */

export class Analytics {
  private sessionStorageKey = 'ask_analytics_session_id';
  private utmStorageKey = 'ask_ad_utm';

  constructor() {
    const searchParams = new URLSearchParams(window.location.search);

    // This is for the initial load as utm has not been saved to session storage yet
    const correctUtm = searchParams.has('utm_source') && searchParams.has('utm_campaign');

    // If the user gets redirected to login or onboarding from an ad campaign, the utm data will be saved to session storage
    const hasSessionStorageUtm = sessionStorage.getItem(this.utmStorageKey);

    if (import.meta.env.VITE_MIXPANEL_TOKEN && (correctUtm || hasSessionStorageUtm)) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
        track_pageview: false,
        debug: import.meta.env.DEV,
        persistence: 'localStorage',
      });
    }
  }

  /**
   * We only want to track events that are coming through ad campaigns for now
   */
  private shouldTrackEvent() {
    if (!import.meta.env.VITE_MIXPANEL_TOKEN) {
      return false;
    }

    const utm = this.getTrackingValues();

    return !!utm;
  }

  private getSessionId() {
    const existing = sessionStorage.getItem(this.sessionStorageKey);

    if (existing) {
      return existing;
    }

    const newId = uid(8).toLowerCase();

    sessionStorage.setItem(this.sessionStorageKey, newId);

    return newId;
  }

  private getTrackingValues() {
    const utmData = sessionStorage.getItem(this.utmStorageKey);

    if (utmData) {
      const parsed = JSON.parse(utmData);

      return parsed;
    }

    return null;
  }

  track(event: string, properties?: Record<string, unknown>) {
    if (!this.shouldTrackEvent()) {
      return;
    }

    const utm = this.getTrackingValues();

    mixpanel.track(event, {
      ...properties,
      ...(utm ? { ...utm } : {}),
    });
  }

  identifySession(userId?: string) {
    if (!this.shouldTrackEvent()) {
      return;
    }

    const identifier = userId ?? this.getSessionId();

    mixpanel.identify(identifier);
  }

  linkAnonSessionWithUser(userId: string, properties?: Record<string, unknown>) {
    if (!this.shouldTrackEvent() || sessionStorage.getItem('tracked_authentication_completed')) {
      return;
    }

    mixpanel.alias(userId, this.getSessionId());

    mixpanel.track('authentication_completed', {
      ...properties,
    });

    sessionStorage.setItem('tracked_authentication_completed', 'true');
  }

  cleanUp() {
    if (import.meta.env.VITE_MIXPANEL_TOKEN) {
      mixpanel.reset();
    }
  }
}

export const analytics = new Analytics();
