import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory'; // TODO: see if this is actually a problem
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { getMainDefinition } from 'apollo-utilities';
import { withClientState } from 'apollo-link-state';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import _ from 'lodash';
import { localStorage, clearUserStorage } from 'lib/storage';
import { WSLink } from './WSLink';
import { IdentifyUser } from './IdentifyUser';
import { AuthWrapper } from './AuthWrapper';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { SplitProvider } from './SplitProvider';
import { UTMTracking } from './UTMTracking';

import './styles/styles.scss';
import Routes from './routes';
import { MonocleProvider } from '@spur.us/monocle-react';
import MonocleLoader from '@spur.us/monocle-loader';
import { FingerprintJSPro, FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  import('@datadog/browser-rum').then(({ datadogRum }) => {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
      site: 'us3.datadoghq.com',
      service: 'participants',
      env: 'production',
      version: import.meta.env.VITE_APP_VERSION,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: false,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [{ match: import.meta.env.VITE_GRAPHQL_URL, propagatorTypes: ['tracecontext'] }],
    });
  });
}

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

// Apollo configuration
let serverUri = null;
const hostname = window.location.hostname.replace(/^([^-]+)-([^.-]*)\.my/, '$1.my'); // Generalize hyphenated subdomains to their prefix
if (hostname === 'my.askable.com') {
  serverUri = 'https://graphql.askable.com/graphql';
} else if (hostname === 'my-dev.askable.com') {
  serverUri = 'https://graphql-dev.askable.com/graphql';
} else if (hostname === 'my-staging.askable.com') {
  serverUri = 'https://graphql-staging.askable.com/graphql';
} else if (hostname.match(/.+\.my\.askable\.com/)) {
  serverUri = 'https://graphql.askable.com/graphql';
} else {
  const localHostname = window.location.hostname.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$|^localhost$/);
  if (localHostname) {
    serverUri = localStorage.get('graphqlUri') || `http://${localHostname[0]}:4000/graphql`;
  }
}

if (!serverUri) serverUri = 'https://graphql.askable.com/graphql';

const httpLink = token =>
  createHttpLink({
    uri: serverUri,
    credentials: 'same-origin',
    headers: {
      ...(token ? { Authorization: token } : {}),
    },
  });

function createSocketLink(token) {
  return new WSLink({
    url: serverUri.replace(/^http/, 'ws'),
    connectionParams: () => {
      return {
        authToken: token,
      };
    },
    options: {
      reconnect: true,
      connectionParams: {
        authToken: token,
      },
    },
  });
}

const links = token =>
  split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    createSocketLink(token),
  );

// Set it up the Local State Link
// Setup the caching system
const cache = new InMemoryCache({ dataIdFromObject: o => o._id, freezeResults: true });

// Setup the link to the global state
const stateLink = withClientState({
  cache,
});

// Setup middleware with context
const middlewareLink = setContext((request, previousContext) => {
  return {
    headers: {
      Accept: 'application/json',
      'x-graphql-client-name': 'participants',
      'x-graphql-client-version': import.meta.env.VITE_APP_VERSION,
      ...previousContext.headers,
    },
  };
});

// Error link
const errorLink = onError(error => {
  const errorMessage = _.get(error, 'graphQLErrors[0].message');
  if (errorMessage === 'jwt expired' || errorMessage === 'Unauthorized') {
    clearUserStorage();
  }
});

// Concat all the links together to create a united link object to get passed to the Apollo Client
const link = token => middlewareLink.concat(stateLink).concat(errorLink).concat(links(token)).concat(httpLink(token));

// Create the Apollo Client with the caching system and links
const createClient = token =>
  new ApolloClient({
    cache,
    link: link(token),
    assumeImmutableResults: true,
  });

const muiTheme = getMuiTheme({
  palette: {
    accent1Color: '#FF5266',
    accent2Color: '#FF5266',
    accent3Color: '#FF5266',
    primary1Color: '#FF5266',
    primary2Color: '#444444',
    primary3Color: '#444444',
    textColor: '#444444',
  },
  fontFamily: 'inherit',
});

const monocle = MonocleLoader({
  token: import.meta.env.VITE_MONOCLE_TOKEN,
});

ReactDOM.render(
  <MuiThemeProvider muiTheme={muiTheme}>
    <ErrorBoundary>
      <FpjsProvider loadOptions={{
          apiKey: import.meta.env.VITE_FP_API_KEY,
          endpoint: [
            import.meta.env.VITE_FP_SUBDOMAIN,
            FingerprintJSPro.defaultEndpoint
          ],
          scriptUrlPattern: [
            `${import.meta.env.VITE_FP_SUBDOMAIN}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
            FingerprintJSPro.defaultScriptUrlPattern
          ]
        }}
      >
        <MonocleProvider instance={monocle}>
          <SplitProvider>
            <AuthWrapper>
              {({ token }) => {
                return (
                  <ApolloProvider client={createClient(token)}>
                    <Routes history={history} token={token} />
                    <IdentifyUser />
                  </ApolloProvider>
                );
              }}
            </AuthWrapper>
          </SplitProvider>
        </MonocleProvider>
      </FpjsProvider>
      <UTMTracking />
    </ErrorBoundary>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
