import React, { useEffect, useState } from 'react';
import { KindeProvider, useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { tryit } from 'radash';

import { useIsKinde } from './useIsKinde';
import { LoadingOverlay } from './components/common';
import { useAddUTMToAppState } from './hooks/useAddUTMToAppState';
import { localStorage } from './lib/storage';
import { analytics } from './Analytics';

function InnerAuth({ children }) {
  const [ready, setIsReady] = useState();
  const [token, setToken] = useState(undefined);
  const { isAuthenticated, isLoading, getToken, user } = useKindeAuth();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (user) {
      analytics.linkAnonSessionWithUser(user.id);
    }

    if (isAuthenticated) {
      getToken().then(r => {
        setToken(r);

        setIsReady(true);
      });
    } else {
      setIsReady(true);
    }
  }, [isLoading, isAuthenticated]);

  if (isLoading || !ready) {
    return null;
  }

  return children({
    token,
  });
}

export function AuthWrapper({ children }) {
  const isKinde = useIsKinde();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('_token') && params.get('_user_id')) {
      localStorage.save('connectedParticipant', params.get('_user_id'));
      localStorage.save('participant_access_token', params.get('_token'));
    }

    setReady(true);
  }, [isKinde]);

  if (!ready) {
    return <LoadingOverlay />;
  }

  if (!isKinde) {
    return children({ token: localStorage.get('participant_access_token') ?? null });
  }

  return (
    <KindeProvider
      isDangerouslyUseLocalStorage={import.meta.env.DEV}
      clientId={import.meta.env.VITE_KINDE_CLIENT_ID}
      domain={import.meta.env.VITE_KINDE_DOMAIN}
      logoutUri={window.location.origin}
      redirectUri={window.location.origin}
      onRedirectCallback={async (_user, app_state) => {
        const appState = app_state;

        const [, url] = await tryit(() => {
          return new URL(appState.redirect_uri);
        })();

        const [, utmObj] = tryit(() => {
          return JSON.parse(appState.utm || '');
        })();
        if (utmObj) {
          // We are going to save after viewer query succeeds. We need to wait as kinde setup is done for the current user in our database
          if (utmObj.utm_source) {
            localStorage.save('utm_source', utmObj.utm_source || '');
          }
          if (utmObj.utm_campaign) {
            localStorage.save('utm_campaign', utmObj.utm_campaign || '');
          }
        }

        if (url) {
          const params = new URLSearchParams(url?.search);
          params.append('redirect', url?.pathname);
          window.location.replace(`${url?.href}?${params.toString()}`, { replace: true });
        }
      }}
    >
      <InnerAuth>
        {({ token }) => {
          return children({ token });
        }}
      </InnerAuth>
    </KindeProvider>
  );
}

export function loginWrapper(Component) {
  return function WrapperComponent(props) {
    // We use register over login here as kinde provides better UX for an existing user on kinde auth flow.
    const { isLoading, register, isAuthenticated } = useKindeAuth();
    const { addUTMToAppState } = useAddUTMToAppState();
    const isKinde = useIsKinde();

    if (!isKinde) {
      return <Component {...props} />;
    }

    useEffect(() => {
      if (isLoading) {
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const redirectPath = (() => {
        try {
          const url = new URL(params.get('redirectPath') || '');
          return url.href;
        } catch (e) {
          return '/';
        }
      })();
      if (isAuthenticated) {
        window.location.replace(redirectPath);

        return;
      }

      register({
        org_code: import.meta.env.VITE_KINDE_PARTICIPANT_ORG_CODE,
        app_state: addUTMToAppState({
          ...(redirectPath ? { redirect_uri: redirectPath } : {}),
        }),
      });
    }, [isLoading]);

    return <LoadingOverlay />;
  };
}

export function withKinde(Component) {
  return function WrapperComponent(props) {
    const isKinde = useIsKinde();
    const kinde = useKindeAuth();

    const isAuthenticated = () => {
      if (isKinde) {
        return kinde.isAuthenticated;
      }

      return !!localStorage.get('participant_access_token');
    };

    if (isKinde && kinde.isLoading) {
      return <LoadingOverlay />;
    }

    return <Component {...kinde} {...props} isKinde={isKinde} isAuthenticated={isAuthenticated} />;
  };
}
