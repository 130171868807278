import { useEffect } from 'react';

export const UTMTracking = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const hasCorrectUtm = params.has('utm_source') && params.has('utm_campaign');

    if (!hasCorrectUtm) {
      return;
    }

    if (params.size > 0) {
      const utmData = {
        utm_source: params.get('utm_source'),
        utm_campaign: params.get('utm_campaign'),
      };
      sessionStorage.setItem('ask_ad_utm', JSON.stringify(utmData));
    }
  }, []);

  return null;
};
